import React, { useEffect, useState } from "react";
import Ticker from "react-ticker";

export default function Marquee({ children, tickerProps }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.setTimeout(() => setShow(true), 100);
    }
  }, []);
  return (
    <div className="w-full" style={{ height: "17px" }}>
      {show && (
        <Ticker height={17} {...tickerProps}>
          {(index) => <React.Fragment key={index}>{children}</React.Fragment>}
        </Ticker>
      )}
    </div>
  );
}
