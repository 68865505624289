import React, { useEffect, useRef, useState } from "react";
import Mic from "../svgs/Mic";
import OutletSwitch from "../svgs/OutletSwitch";
import Phone from "../svgs/Phone";
import MapPin from "../svgs/MapPin";
import Instagram from "../svgs/Instagram";
import Arrow from "../svgs/Arrow";
import Oscilloscope from "oscilloscope";
import Marquee from "../components/marquee";
import { motion, useAnimation } from "framer-motion";
import SEO from "react-seo-component";
import Contact from "../components/contact";
import { StaticImage } from "gatsby-plugin-image";

const bgs = [
  "rgb(254,21,61)",
  "rgb(254,150,179)",
  "rgb(255,165,62)",
  "rgb(251,227,139)",
  "rgb(32,175,152)",
  "rgb(185,225,213)",
  "rgb(121,195,243)",
  "rgb(139,70,179)",
];
const bgTxt = [
  "rgb(254,21,61)",
  "rgb(254,150,179)",
  "rgb(255,165,62)",
  "rgb(251,227,139)",
  "rgb(32,175,152)",
  "rgb(185,225,213)",
  "rgb(121,195,243)",
  "rgb(139,70,179)",
];

const Index = () => {
  const controller = useAnimation();
  const canvasEl = useRef(null);
  const [ix, setIx] = useState(0);
  const [scope, setScope] = useState();
  const [isContactOpen, setContactOpen] = useState(false);

  const toggleContact = () => {
    setContactOpen(!isContactOpen);
    controller.start(isContactOpen ? "hidden" : "visible");
  };

  const initOsc = () => {
    if (typeof window !== "undefined") {
      var AudioContext =
        window.AudioContext || // Default
        window.webkitAudioContext;
      const audioContext = new AudioContext();
      console.debug(`Running init osc.`);
      // Older browsers might not implement mediaDevices at all, so we set an empty object first
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }

      // Some browsers partially implement mediaDevices. We can't just assign an object
      // with getUserMedia as it would overwrite existing properties.
      // Here, we will just add the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // First get ahold of the legacy getUserMedia, if present
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }

          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      // setup canvas
      const canvas = canvasEl.current;

      // customize drawing options
      const ctx = canvas.getContext("2d");
      ctx.lineWidth = 5;
      ctx.strokeStyle = "#ffffff";

      // get user microphone
      const constraints = { video: false, audio: true };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          const source = audioContext.createMediaStreamSource(stream);

          // attach oscilloscope
          const scope = new Oscilloscope(source, { fftSize: 1024 * 4 });

          // start default animation loop
          scope.animate(ctx);
          setScope(scope);
        })
        .catch((error) => {
          console.error("getUserMedia error:", error);
        });
    }
  };

  function plotSine(ctx, xOffset, yOffset) {
    var width = window.innerWidth * window.devicePixelRatio;
    var height = Math.round(window.innerHeight / 2) * window.devicePixelRatio;

    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.strokeStyle = "white";

    var x = 4;
    var y = 0;
    var amplitude = 150;
    var frequency = 100;
    while (x < width) {
      y = height / 2 + amplitude * Math.sin((x + xOffset) / frequency);
      ctx.lineTo(x, y);
      x++;
    }
    ctx.stroke();
    ctx.save();

    ctx.stroke();
    ctx.restore();
  }
  function draw() {
    var canvas = document.getElementById("canvas");
    var context = canvas.getContext("2d");

    context.clearRect(0, 0, 100000, 100000);

    plotSine(context, step, 50);
    // context.restore();

    // step += 1;
    window.requestAnimationFrame(draw);
  }
  const resizeCanvas = () => {
    canvasEl.current.height =
      Math.round(window.innerHeight / 2) * window.devicePixelRatio;
    canvasEl.current.width = window.innerWidth * window.devicePixelRatio;
    canvasEl.current.style = `width: ${
      window.innerWidth
    }px; height: ${Math.round(window.innerHeight / 2)}px`;
  };
  function init() {
    draw();
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
  }

  useEffect(init, []);

  var step = -4;

  return (
    <div
      style={{
        backgroundColor: bgs[ix],
      }}
      className="relative grid w-full h-full grid-rows-6 overflow-hidden text-white osmose"
    >
      <SEO
        title={"SomeFriends"}
        titleTemplate={`SomeFriends`}
        description={`SomeFriends Media is a new premium podcast-first entertainment company telling stories by and about people of color, for everybody.`}
        image={`/favicon.png`}
      />
      <motion.div
        animate={controller}
        className="absolute top-0 right-0 z-10 flex flex-col items-center justify-center w-full h-full max-w-full bg-white md:w-96 md:justify-start"
        style={{ fontSize: "24px" }}
        variants={{
          hidden: {
            transform: "translateX(100%)",
          },
          visible: {
            transform: "translateX(0%)",
          },
        }}
        transition={{
          duration: 1,
        }}
        initial="hidden"
      >
        <div className="absolute top-4 left-4">
          <button style={{ color: bgs[ix] }} onClick={() => toggleContact()}>
            <Arrow />
          </button>
        </div>
        <motion.a
          href="tel:+12132665112"
          className="flex items-center justify-center w-64 mx-auto text-center border-4 h-52 md:h-64 md:mt-20"
          style={{ borderColor: bgs[ix], color: bgs[ix] }}
          whileHover={{ backgroundColor: bgs[ix], color: "white" }}
        >
          call
          <br />
          ☎️
        </motion.a>
        <motion.a
          href="mailto:hello@somefriendsmedia.com"
          className="flex flex-col items-center justify-center w-64 mx-auto text-center border-4 border-t-0 h-52 md:h-64"
          style={{ borderColor: bgs[ix], color: bgs[ix] }}
          whileHover={{ backgroundColor: bgs[ix], color: "white" }}
        >
          email
          <br />
          💻
          <br />
          <span className="text-sm">hello@somefriendsmedia.com</span>
        </motion.a>
        <div
          className="md:mb-20"
          style={{ borderColor: bgs[ix], color: bgs[ix] }}
        >
          <Contact />
        </div>
      </motion.div>
      <section className="flex items-center self-start justify-between p-4">
        <button
          onClick={() => {
            const newIx = ix + 1;
            setIx(newIx > bgs.length - 1 ? 0 : newIx);
          }}
        >
          <OutletSwitch />
        </button>
        <button onClick={() => toggleContact()}>
          <Phone />
        </button>
      </section>
      <section className="flex items-center flex-grow row-span-2 md:row-span-3">
        <canvas id="canvas" width="500" height="400" ref={canvasEl} />
      </section>
      <div className="flex flex-col justify-end row-span-3">
        <section className="relative flex items-center justify-center my-12">
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex flex-col items-center my-4">
              <button
                onClick={() => {
                  if (!scope) {
                    initOsc();
                  } else {
                    scope.stop();
                    setScope(null);
                  }
                }}
              >
                <Mic />
              </button>
              <span className="mt-4 text-xs">(tap me & say something)</span>
            </div>
            <h1 className="mx-6 my-2" style={{ maxWidth: 460 }}>
              <StaticImage
                src="../img/logo.png"
                alt="Logo"
                placeholder="tracedSVG"
              />
            </h1>
            <h2 className="my-2" style={{ fontFamily: "Osmose", fontSize: 24 }}>
              We tell{" "}
              {"colorful".split("").map((letter, i) => {
                let pick = i % bgTxt.length;
                let color = bgTxt[pick];
                if (pick === ix) color = "white";
                return (
                  <span key={i} style={{ color: color }}>
                    {letter}
                  </span>
                );
              })}{" "}
              stories.
            </h2>
            <div className="block w-full my-2 text-sm whitespace-nowrap">
              <Marquee>
                <span className="mx-2">
                  SomeFriends is a new premium podcast-first entertainment
                  company telling stories by and about people of color, for
                  everybody.
                </span>
              </Marquee>
            </div>
          </div>
        </section>
        <section className="flex items-end justify-between p-4">
          <a
            className="block"
            href="https://www.instagram.com/somefriendsmedia/"
          >
            <Instagram />
          </a>
          <div>
            ny
            <MapPin className="inline-block mx-2" />
            la
          </div>
        </section>
      </div>
    </div>
  );
};

export default Index;
