import React from "react";

const Arrow = (props) => (
  <svg
    width="65"
    height="28"
    viewBox="0 0 65 28"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M51.1 27.999C51.7025 27.981 52.2744 27.7281 52.6945 27.2941L64.0865 15.856C64.3697 15.6393 64.5994 15.36 64.7578 15.0398C64.9163 14.7196 64.9991 14.367 65 14.0095C65.0009 13.652 64.9197 13.299 64.7629 12.978C64.606 12.6571 64.3777 12.3766 64.0955 12.1585C64.091 12.1555 64.0865 12.1524 64.0819 12.1494L52.6945 0.715925C52.4808 0.492447 52.2248 0.314026 51.9416 0.191114C51.6583 0.068203 51.3535 0.0032711 51.045 0.000118256C50.7364 -0.00303268 50.4304 0.0556583 50.1447 0.172758C49.859 0.289858 49.5995 0.463017 49.3813 0.682083C49.1631 0.90115 48.9907 1.16172 48.874 1.44855C48.7574 1.73537 48.6989 2.04268 48.7021 2.35247C48.7052 2.66226 48.7699 2.96831 48.8923 3.25269C49.0147 3.53707 49.1924 3.79407 49.415 4.00863L57.052 11.6764L2.3522 11.6764C2.04488 11.6721 1.73977 11.7291 1.45458 11.8442C1.1694 11.9592 0.909828 12.13 0.690971 12.3467C0.472115 12.5634 0.298325 12.8215 0.179703 13.1062C0.0610809 13.3909 0 13.6964 0 14.005C0 14.3136 0.0610809 14.6191 0.179703 14.9038C0.298325 15.1885 0.472115 15.4466 0.690971 15.6633C0.909828 15.8799 1.1694 16.0508 1.45458 16.1658C1.73977 16.2809 2.04488 16.3379 2.3522 16.3335L57.052 16.3335L49.415 24.0014C49.0799 24.3284 48.851 24.7495 48.7583 25.2095C48.6656 25.6694 48.7135 26.1467 48.8957 26.5788C49.0778 27.011 49.3857 27.3778 49.7791 27.6312C50.1724 27.8846 50.6329 28.0128 51.1 27.999Z"
      fill="currentColor"
    />
  </svg>
);

export default Arrow;
