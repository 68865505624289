import React from "react";

export default (props) => (
  <svg
    width="71"
    height="63"
    viewBox="0 0 71 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.8709 19.901L25.6615 24.125C26.4417 25.9656 26.0094 28.1302 24.5907 29.476L21.2448 32.651C20.8677 33.0094 20.6719 33.5292 20.7563 34.0417C21.0792 36.001 21.7948 37.9333 22.9032 39.8396C24.0094 41.7417 25.3563 43.3604 26.9448 44.6948C27.3657 45.0479 27.95 45.1396 28.4709 44.9656L32.374 43.6635C34.1709 43.0646 36.1271 43.7531 37.2292 45.3719L39.7969 49.1437C41.0782 51.026 40.8479 53.6219 39.2584 55.2177L37.5542 56.9281C35.8584 58.6302 33.4365 59.2479 31.1959 58.549C25.9063 56.899 21.0438 52.001 16.6063 43.8542C12.1625 35.6958 10.5948 28.7729 11.9021 23.0875C12.4521 20.6948 14.1552 18.7896 16.3792 18.0792L18.6219 17.3625C20.7261 16.6927 22.9709 17.7781 23.8709 19.901Z"
      fill="white"
    />
    <path
      d="M63.3333 7.66667H32.6666C30.5487 7.66667 28.8333 9.38209 28.8333 11.5V34.5C28.8333 36.6179 30.5487 38.3333 32.6666 38.3333H63.3333C65.4512 38.3333 67.1666 36.6179 67.1666 34.5V11.5C67.1666 9.38209 65.4512 7.66667 63.3333 7.66667ZM62.5685 15.8106L49.0158 24.2823C48.3948 24.6713 47.6051 24.6713 46.9841 24.2823L33.4313 15.8106C32.956 15.5135 32.6666 14.9922 32.6666 14.4325C32.6666 13.156 34.0715 12.3778 35.1525 13.0544L47.9999 21.0833L60.8473 13.0544C61.9283 12.3778 63.3333 13.156 63.3333 14.4325C63.3333 14.9922 63.0438 15.5135 62.5685 15.8106Z"
      fill="white"
    />
  </svg>
);
