import React from "react";

export default (props) => (
  <svg
    width="14"
    height="25"
    viewBox="0 0 14 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 0C3.134 0 0 3.134 0 7C0 10.5253 2.60936 13.4332 6 13.9199V23C5.99813 23.1325 6.02261 23.2641 6.07202 23.387C6.12143 23.51 6.1948 23.6219 6.28784 23.7163C6.38088 23.8107 6.49176 23.8856 6.61401 23.9368C6.73627 23.9879 6.86748 24.0142 7 24.0142C7.13252 24.0142 7.26373 23.9879 7.38599 23.9368C7.50824 23.8856 7.61912 23.8107 7.71216 23.7163C7.8052 23.6219 7.87857 23.51 7.92798 23.387C7.97739 23.2641 8.00187 23.1325 8 23V13.9199C11.3906 13.4332 14 10.5253 14 7C14 3.134 10.866 0 7 0ZM7 2C8.243 2 9.25 2.672 9.25 3.5C9.25 4.328 8.243 5 7 5C5.757 5 4.75 4.328 4.75 3.5C4.75 2.672 5.757 2 7 2Z"
      fill="white"
    />
  </svg>
);
