import React, { useState } from "react";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [sentEmail, setSent] = useState(false);
  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        try {
          fetch(
            "https://docs.google.com/forms/d/e/1FAIpQLSexSQSaX95G9-0vfAP05E1ra36Vw3X1gX_GRDaaxnKnG3ofTA/formResponse?" +
              "entry.2083103009=" +
              encodeURIComponent(email),
            {
              mode: "no-cors",
            }
          ).finally(() => {
            setSent(true);
            setEmail("Sent!");
            if (typeof window !== "undefined") {
              window.setTimeout(() => {
                setSent(false);
                setEmail("");
              }, 1500);
            }
          });
        } catch (error) {}
      }}
    >
      <label>
        <span className="text-sm">Get Updates</span>
        <div className="grid w-64 grid-cols-4 border-4 border-current">
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className="col-span-3 p-2 text-base"
            required
          />
          <button
            type="submit"
            className="p-2 text-2xl border-l-4 border-current"
          >
            {sentEmail ? "✓" : "📭"}
          </button>
        </div>
      </label>
    </form>
  );
};

export default Contact;
