import React from "react";

const Mic = (props) => (
  <svg
    width="35"
    height="50"
    viewBox="0 0 35 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3077 0C11.9967 0 7.69231 4.30439 7.69231 9.61538V25C7.69231 30.311 11.9967 34.6154 17.3077 34.6154C17.6382 34.6154 17.9462 34.5853 18.2692 34.5553C21.1764 34.2623 23.7455 32.6472 25.3005 30.3486C26.3371 28.8161 26.9231 26.9907 26.9231 25V9.61538C26.9231 4.30439 22.6187 0 17.3077 0ZM17.3077 3.84615C19.8092 3.84615 21.9126 5.46875 22.7163 7.69231H21.1538C20.0947 7.69231 19.2308 8.55619 19.2308 9.61538C19.2308 11.4709 21.1538 11.5385 21.1538 11.5385H23.0769V13.4615H21.1538C20.0947 13.4615 19.2308 14.3254 19.2308 15.3846C19.2308 17.2401 21.1538 17.3077 21.1538 17.3077H23.0769V19.2308H11.5385V17.3077H13.4615C13.4615 17.3077 15.3846 17.2401 15.3846 15.3846C15.3846 14.3254 14.5207 13.4615 13.4615 13.4615H11.5385V11.5385H13.4615C13.4615 11.5385 15.3846 11.4709 15.3846 9.61538C15.3846 8.55619 14.5207 7.69231 13.4615 7.69231H11.899C12.7028 5.46875 14.8062 3.84615 17.3077 3.84615ZM1.92308 21.1538C0.210337 21.1538 0 23.0769 0 23.0769V25C0 33.2181 5.76172 40.0691 13.4615 41.8269V46.1538H7.69231C5.84435 46.1538 5.76923 48.0769 5.76923 48.0769V50H28.8462V48.0769C28.8462 47.0177 27.9823 46.1538 26.9231 46.1538H21.1538V41.8269C28.8537 40.0691 34.6154 33.2181 34.6154 25V23.0769C34.6154 23.0769 34.4351 21.1538 32.6923 21.1538C30.9495 21.1538 30.7692 23.0769 30.7692 23.0769V25C30.7692 32.4219 24.7296 38.4615 17.3077 38.4615C9.88582 38.4615 3.84615 32.4219 3.84615 25V23.0769C3.84615 23.0769 3.63582 21.1538 1.92308 21.1538Z"
      fill="white"
    />
  </svg>
);

export default Mic;
