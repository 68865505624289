import React from "react";

const OutletSwitch = (props) => (
  <svg
    width="36"
    height="50"
    viewBox="0 0 36 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.7402 50.0625H3.38617C1.78467 50.0625 0.482666 48.7605 0.482666 47.159V2.966C0.482666 1.3645 1.78467 0.0625 3.38617 0.0625H32.7402C34.3412 0.0625 35.6437 1.3645 35.6437 2.966V47.159C35.6437 48.7605 34.3417 50.0625 32.7402 50.0625ZM3.85167 46.6935H32.2747V3.4315H3.85167V46.6935Z"
      fill="white"
    />
    <path
      d="M24.2848 25.877C24.2093 25.7275 24.1473 25.4685 24.1473 25.301V14.769C24.1473 14.6015 24.0103 14.464 23.8423 14.464H12.3773C12.2098 14.464 12.0728 14.601 12.0728 14.769V30.451C12.0728 30.6185 12.1323 30.879 12.2063 31.0295L14.2983 35.34C14.3718 35.49 14.4338 35.6055 14.4353 35.5965C14.4378 35.5865 14.4508 35.601 14.4653 35.629C14.4793 35.6565 14.6273 35.6795 14.7948 35.6795H24.8023C24.9703 35.6795 25.1608 35.5535 25.2268 35.3985L26.8858 31.4925C26.9513 31.3375 26.9428 31.089 26.8668 30.9405L24.2848 25.877ZM16.9273 29.6365C16.7598 29.6365 16.5703 29.51 16.5058 29.354L15.1713 26.1305C15.1073 25.975 15.1918 25.8485 15.3593 25.8485H21.6273C21.7953 25.8485 21.9963 25.97 22.0738 26.1185L23.7788 29.3665C23.8563 29.5155 23.7833 29.637 23.6158 29.637H16.9273V29.6365ZM20.8578 17.0195C21.0253 17.0195 21.5923 17.817 21.7828 18.891C22.0878 20.6055 22.0428 23.7895 22.0428 23.796C22.0428 23.802 21.9058 23.8065 21.7383 23.8065H14.9333C14.7653 23.8065 14.6283 23.67 14.6283 23.502V17.324C14.6283 17.156 14.7653 17.019 14.9333 17.019H20.8578V17.0195ZM16.4718 33.8155C16.3038 33.8155 16.2133 33.6865 16.2718 33.529L16.8408 31.9645C16.8993 31.806 17.0823 31.677 17.2503 31.677H24.2813C24.4488 31.677 24.5323 31.8035 24.4658 31.9575L23.7883 33.535C23.7218 33.689 23.5313 33.8155 23.3633 33.8155H16.4718Z"
      fill="white"
    />
  </svg>
);

export default OutletSwitch;
